/**
 * Asynchronously fetches suggestion items based on the provided request string using Yandex Maps suggest API.
 * Each item in the response is transformed to include only the display name of the suggested location.
 * @param {string} request - The request string used for fetching suggestions. Typically, this is a partial address or location query.
 * @returns {Promise<Array<{text: string, value: string}>>} A promise that resolves to an array of objects, each containing the 'text' and 'value' properties set to the display name of the suggestion.
 */
export const getSugggest = request => {
    return ymaps.suggest(request).then(function (items) {
        return items.map(item => ({
            text: item.displayName,
            value: item.displayName,
        }));
    });
};
